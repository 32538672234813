

.background-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  background-color: #f0f0f0;
  background-image: url('../assets/main.jpg');
  opacity: 50%;
  
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}


.centered-text {
  /* position: absolute;
  text-align: center;
  font-size: 2rem;
  color: #ffffff; */
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* padding: 1rem;
  border-radius: 8px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);*/
} 