.c-container{
    display: flex;
    justify-content: center;
    align-items: center;

  padding: 2px 16px;


  background-color: #770D06;
    

}
.content{
    text-align: center;
}
.content p{
    font-size: larger;
    color: white;
}
.contact-content a{
    color: white;
    font-size: larger;

}
.content h2{
    color: white;
    font-size: larger;

}
.contact-content a:hover{
    color: grey;
}

.contact-container{
    display: flex;
    flex-direction: row;
    align-items: unset;
    justify-content: center;
    
}
.svg_icons {
    transform: scale(1.5);
  }

.contact-content{
    padding: 20px;
}